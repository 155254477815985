/*
SIBEL INC ("SIBEL HEALTH") CONFIDENTIAL
Copyright 2018-2021 [Sibel Inc.], All Rights Reserved.NOTICE: All information contained herein is, and remains the property of SIBEL
INC. The intellectual and technical concepts contained herein are proprietary 
to SIBEL INC and may be covered by U.S. and Foreign Patents, patents in 
process, and are protected by trade secret or copyright law. Dissemination of
this information or reproduction of this material is strictly forbidden unless
prior written permission is obtained from SIBEL INC. Access to the source code
contained herein is hereby forbidden to anyone except current SIBEL INC 
employees, managers or contractors who have executed Confidentiality and 
Non-disclosure agreements explicitly covering such access.The copyright notice above does not evidence any actual or intended 
publication or disclosure of this source code, which includes information that
is confidential and/or proprietary, and is a trade secret, of SIBEL INC.ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC
DISPLAY OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN
CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE
LAWS AND INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE
CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO
REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR
SELL ANYTHING THAT IT MAY DESCRIBE, IN WHOLE OR IN PART.
*/

import React from "react";
import "node-fetch";
import "./Popup.css";
import IconDoctor from "../assets/icon_doctor.png";
import CloseIcon from "../assets/icon_close.png";
/*
  Popup.js:
  - Path: None
  - Description: This is the component that creates a popup with a specific data
*/

const Popup = ({ trigger, passedData, setTrigger, onButtonClick }) => {
  const ANTHEM_LINK = "https://www.anthem.com/";
  const SYDNEY_LINK = "https://www.anthem.com/login/?dplid=sso.dpl.rcp.lho/sleep-practice"

  const isMobile = /Mobi|Android/i.test(navigator.userAgent)

  const closePopup = () => {
    setTrigger(false);
  };
  const linkOptions = () => {
    if (isMobile) {
      window.open(SYDNEY_LINK, "_blank");
    } else {
      window.open(ANTHEM_LINK, "_blank");
    }
    onButtonClick();
  };

  var steps;

  if (isMobile) {
    steps = <>
      <ul className="steps">
        <li>Login to your Sydney app</li>
        <li>Click on “Find Care”</li>
        <li>Click on “virtual visit with a doctor”</li>
        <li>Read through and select “continue”</li>
        <li>Provide necessary information and select “visit now” under the “Sleep” tile</li>
      </ul>
    </>
  } else {
    steps = <>
      <ul className="steps">
        <li>Login to Anthem</li>
        <li>Select “Virtual visit with a provider”</li>
        <li>Navigate and select “visit now” under the “Sleep” tile</li>
      </ul>
    </>
  }

  if (trigger) {
    return (
      <div className="popup">
        <div className="popup-inner">
          <img className="close-btn" src={CloseIcon} onClick={closePopup} alt="close"></img>
          <img className="inner-icon" src={IconDoctor} alt="" />
          <div className="inner-data">
            <div>{passedData}</div>
            <br></br>
            <div>If you wish to follow through for a virtual visit with a sleep specialist, then some helpful directions are below:</div>
            <div>{steps}</div>
          </div>

          <button className="link-btn" onClick={linkOptions}>
            See Care Option
          </button>
        </div>
      </div>
    );
  } else return "";
};

export default Popup;
