/*
SIBEL INC ("SIBEL HEALTH") CONFIDENTIAL
Copyright 2018-2021 [Sibel Inc.], All Rights Reserved.NOTICE: All information contained herein is, and remains the property of SIBEL
INC. The intellectual and technical concepts contained herein are proprietary 
to SIBEL INC and may be covered by U.S. and Foreign Patents, patents in 
process, and are protected by trade secret or copyright law. Dissemination of
this information or reproduction of this material is strictly forbidden unless
prior written permission is obtained from SIBEL INC. Access to the source code
contained herein is hereby forbidden to anyone except current SIBEL INC 
employees, managers or contractors who have executed Confidentiality and 
Non-disclosure agreements explicitly covering such access.The copyright notice above does not evidence any actual or intended 
publication or disclosure of this source code, which includes information that
is confidential and/or proprietary, and is a trade secret, of SIBEL INC.ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC
DISPLAY OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN
CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE
LAWS AND INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE
CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO
REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR
SELL ANYTHING THAT IT MAY DESCRIBE, IN WHOLE OR IN PART.
*/

import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import "node-fetch";
import PageHeader from "../components/LandingPageHeader.js";
import PageFooter from "../components/PageFooter.js";
import TimerComponent from "../components/TimerComponent.js";
import Popup from "../components/Popup.js";
import PostSurveyPopup from "../components/PostSurveyPopup.js";
import EmailShare from "../components/EmailShare.js";
import StatusStageBar from "../components/StatusStageBar.js";
import { useSelector, useDispatch } from "react-redux";
import { clearCredentials, storeCredentials } from "../redux/actions";
import { getCredentialsState } from "../redux/selectors";
import CardProfile from "../assets/card-profile.png";
import CareOption from "../assets/care-option-big.png";
import CardEmail from "../assets/dashboard-email.png";
import CardPhone from "../assets/dashboard-phone.png";
import CardAddress from "../assets/dashboard-address.png";
import {
  refreshAndCallWithIdToken,
  REFRESH_TOKEN_TIME,
} from "../helpers/credentialsHelper";
import { updateUserStatus } from "../helpers/statusHelper.js";
import "./Dashboard.css";
import getPages from "../helpers/userFlowHelper.js";
/*
  Dashboard.js:
  - Path: /dashboard
  - Description: This is the page for dashboard
*/

const Dashboard = () => {
  const LIST_REPORTS_PATH = "/list-reports";
  const LIST_SURVEYS_PATH = "/list-surveys";
  const GET_REPORT_PATH = "/sleep-report";
  const SHARE_REPORT_PATH = "/email-report";
  const CARE_OPTION_PATH = "/care-options";

  const [reportList, setReportList] = useState([]);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [username, setUsername] = useState("");
  const [testingNight, setTestingNight] = useState("");
  const [address, setAddress] = useState("");
  const [buttonPopup, setButtonPopup] = useState(false);
  const [surveyPopup, setSurveyPopup] = useState(false);
  const [shareDate, setShareDate] = useState(null);

  const [postTestProp, setPostTestProp] = useState("still");
  const postTestPropRef = useRef("NOT DONE");
  const [postCareProp, setPostCareProp] = useState("still");
  const postCarePropRef = useRef("NOT DONE");
  const [survey, setSurvey] = useState("");
  const [userPage, setUserPage] = useState(false);
  const AUTO_LOGOUT_TIME = REFRESH_TOKEN_TIME;

  const history = useHistory();
  let credentials = useSelector(getCredentialsState);
  const dispatch = useDispatch();

  useEffect(
    (e) => {
      const BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const USER_PATH = "/user";

      const checkHistory = () => {
        if (history.location.search === "?survey=post-test-survey") {
          setSurvey("post-test-survey");
          setSurveyPopup(true);
          setPostTestProp("done");
          window.history.replaceState({}, "", "/dashboard");
        } else if (history.location.search === "?survey=post-care-survey") {
          setSurvey("post-care-survey");
          setSurveyPopup(true);
          setPostTestProp("done");
          window.history.replaceState({}, "", "/dashboard");
        }
      };

      const getUserInfo = async (accessToken, idToken) => {
        const getUserEndpoint = new URL(BASE_URL + USER_PATH);
        const getUserHeaders = {
          Authorization: idToken,
          "Access-Token": accessToken,
        };
        try {
          let response = await fetch(getUserEndpoint, {
            method: "get",
            headers: getUserHeaders,
          });
          let data = await response.json();
          if (response.status === 200) {
            const attributes = data.UserAttributes;
            const email = attributes.find((att) => att.Name === "email").Value;
            const fullName = attributes.find(
              (att) => att.Name === "name"
            ).Value;
            let phoneNumberValue = attributes.find(
              (att) => att.Name === "phone_number"
            ).Value;

            let addressValue = attributes.find(
              (att) => att.Name === "address"
            ).Value;

            const testingNightDate = attributes.find(
              (att) => att.Name === "custom:start_date"
            ).Value;
            addressValue = addressValue.replace(/,/g, "\n");
            phoneNumberValue =
              phoneNumberValue.substring(2, 5) +
              "-" +
              phoneNumberValue.substring(5, 8) +
              "-" +
              phoneNumberValue.substring(8);
            setUsername(email);
            setName(fullName);
            setPhoneNumber("+1 " + phoneNumberValue);
            setAddress(addressValue);
            const testNightString = testingNightDate.split("-");
            setTestingNight(
              testNightString[1] +
                "/" +
                testNightString[2] +
                "/" +
                testNightString[0]
            );

            const postTest = attributes.find(
              (att) => att.Name === "custom:post_test_complete"
            );

            if (postTest && postTest.Value === "true") {
              setPostTestProp("done");
              postTestPropRef.current = "DONE";
            }

            const postCare = attributes.find(
              (att) => att.Name === "custom:post_care_complete"
            );

            if (postCare && postCare.Value === "true") {
              setPostCareProp("done");
              postCarePropRef.current = "DONE";
            }
          } else {
            setUsername("N/A");
            setName("N/A");
            setPhoneNumber("N/A");
            setAddress("N/A");
            setTestingNight("N/A");
          }
        } catch (error) {
          setUsername("N/A");
          setName("N/A");
          setPhoneNumber("N/A");
          setAddress("N/A");
          setTestingNight("N/A");
        }
      };

      const getReportList = async (accessToken, idToken) => {
        const endpoint = new URL(
          process.env.REACT_APP_API_BASE_URL + LIST_REPORTS_PATH
        );
        const headers = {
          "Access-Token": accessToken,
          Authorization: idToken,
        };
        try {
          let response = await fetch(endpoint, {
            method: "GET",
            headers: headers,
          });
          let data = await response.json();
          data = data.message;
          if (data.length > 0) {
            setReportList([data[0]]);
          }
        } catch (error) {
          // console.log(error);
        }
      };

      const getSurveyList = async (accessToken, idToken) => {
        const endpoint = new URL(
          process.env.REACT_APP_API_BASE_URL + LIST_SURVEYS_PATH
        );
        const headers = {
          "Access-Token": accessToken,
          Authorization: idToken,
        };
        try {
          let response = await fetch(endpoint, {
            method: "GET",
            headers: headers,
          });
          let data = await response.json();
          data = data.surveys;
          if (
            data.includes("post-test") &&
            postTestProp === "still" &&
            postTestPropRef.current !== "DONE"
          ) {
            setPostTestProp("progress");
          }
          if (
            data.includes("post-care") &&
            postCareProp === "still" &&
            postCarePropRef.current !== "DONE"
          ) {
            setPostCareProp("progress");
          }
        } catch (error) {
          // console.log(error);
        }
      };

      refreshAndCallWithIdToken(
        credentials,
        function (accessToken, idToken, credentials) {
          checkHistory();
          getUserInfo(accessToken, idToken);
          getPages(accessToken, idToken, { history, setUserPage });
          getReportList(accessToken, idToken);
          getSurveyList(accessToken, idToken);
          if (credentials !== null) {
            dispatch(storeCredentials(credentials));
          }
        }
      );
    },
    [credentials, dispatch, postCareProp, postTestProp, history]
  );

  const onChangePassword = () => {
    console.log("Redirecting to change-password");
    history.push({
      pathname: "/change-password",
    });
  };

  const getReport = async (date, accessToken, idToken) => {
    const endpoint = new URL(
      process.env.REACT_APP_API_BASE_URL + GET_REPORT_PATH
    );
    const headers = {
      "Access-Token": accessToken,
      Authorization: idToken,
      "Report-Date": date,
    };
    try {
      let response = await fetch(endpoint, {
        method: "GET",
        headers: headers,
      });
      let data = await response.json();
      data = await data.message;
      var newWindow = window.open();
      newWindow.location = data;
    } catch (error) {
      console.log("Get report failed");
      // console.log(error);
    }
  };

  const onGetReport = (reportName) => {
    refreshAndCallWithIdToken(
      credentials,
      function (accessToken, idToken, credentials) {
        getReport(reportName, accessToken, idToken);
        if (credentials !== null) {
          dispatch(storeCredentials(credentials));
        }
      }
    );
  };

  const shareReport = async (date, email, accessToken, idToken) => {
    // console.log(date);
    const endpoint = new URL(
      process.env.REACT_APP_API_BASE_URL + SHARE_REPORT_PATH
    );
    const headers = {
      "Access-Token": accessToken,
      Authorization: idToken,
      "Report-Date": date,
      Email: email,
    };
    try {
      await fetch(endpoint, {
        method: "POST",
        headers: headers,
      });
      console.log("Email sent");
    } catch (error) {
      console.log("share report failed");
      // console.log(error);
    }
  };

  const onShareReport = (date, email) => {
    refreshAndCallWithIdToken(
      credentials,
      function (accessToken, idToken, credentials) {
        shareReport(date, email, accessToken, idToken);
        if (credentials !== null) {
          dispatch(storeCredentials(credentials));
        }
      }
    );
  };

  const careOption = async (accessToken, idToken) => {
    const endpoint = new URL(
      process.env.REACT_APP_API_BASE_URL + CARE_OPTION_PATH
    );
    const headers = {
      "Access-Token": accessToken,
      Authorization: idToken,
    };
    try {
      let response = await fetch(endpoint, {
        method: "POST",
        headers: headers,
      });
      let data = await response.json();
      data = await data.message;
    } catch (error) {
      console.log("Get report failed");
      // console.log(error);
    }
  };

  const onCareOptionClicked = () => {
    refreshAndCallWithIdToken(
      credentials,
      function (accessToken, idToken, credentials) {
        careOption(accessToken, idToken);
        if (credentials !== null) {
          dispatch(storeCredentials(credentials));
        }
      }
    );
  }

  const onLogout = () => {
    console.log("Logging out");
    dispatch(clearCredentials());
    history.push({
      pathname: "/login",
    });
  };

  const onCareOption = () => {
    setButtonPopup(true);
    console.log("Care option.");
  };

  const onPostTest = () => {
    console.log("POST TEST");
    updateUserStatus(credentials, "dashboard", () => {
      history.push({
        pathname: "/survey",
      });
    });
  };

  const onPostCare = () => {
    console.log("POST CARE");
    updateUserStatus(credentials, "survey-post-trial-1", () => {
      history.push({
        pathname: "/survey",
      });
    });
  };

  let pageHeader;
  let pageHeaderDetails;
  let pageFooter;
  let pageFooterDetails;

  if (postTestProp === "still" && postCareProp === "still") {
    pageHeader = (
      <div className="dashboard-banner-title-enroll">You're enrolled!</div>
    );
    pageHeaderDetails = (
      <div className="dashboard-banner-text">
        Once you have finished the testing night, you’ll receive your sleep
        report.
      </div>
    );
    pageFooter = (
      <div className="dashboard-bottom-title-still ">
        Better sleep is around the corner.
      </div>
    );
  } else {
    pageHeader = (
        <div className="dashboard-banner-title">Your Sleep Report Is Ready.</div>
    );
    pageHeaderDetails = (
      <div className="dashboard-banner-text-report">
        Download your report and/or visit LiveHealth Online sleep specialists to discuss.
      </div>
    )
    pageFooter = (
      <div className="dashboard-bottom-title">Need diagnosis or treatment?</div>
    );
    pageFooterDetails = (
      <button className="dashboard-bottom-btn" onClick={onCareOption}>
        See Care Option
      </button>
    );
  }

  if (userPage === true) {
    return (
      <>
        <TimerComponent time={AUTO_LOGOUT_TIME} destination={"/logout"} />
        <PageHeader text="Log out" onButtonClick={onLogout} />
        <div className="dashboard-main">
          {/* Dashboard Banner Start */}
          <div className="dashboard-banner">
            <div className="dashboard-gradient"></div>
            <div className="banner-img" />
            <div className="dashboard-banner-box">
              <div id="firstBox" className="dashboard-banner-item"></div>
              <div className="dashboard-banner-item">
                <div className="dashboard-description-box">
                  <div className="dashboard-banner-info">
                    {pageHeader}
                    {pageHeaderDetails}

                    {/* Share and Dowload buttons Start */}
                    {reportList.length > 0 ? (
                      reportList.map((report, index) => {
                        const date = report.substring(0, 10);
                        return (
                          <li className="list-report" key={`report${index}`}>
                            <button
                              className="download-dashboard-btn"
                              onClick={() => onCareOption()}
                            >
                              Visit LiveHealth Online
                            </button>
                            <button
                              className="banner-share-btn"
                              onClick={() => onGetReport(date)}
                            >
                              Download Sleep Report
                            </button>
                          </li>
                        );
                      })
                    ) : (
                      <div></div>
                    )}

                    {/* Share and Dowload buttons End */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Dashboard Banner End */}
          {/* Stepper Start */}
          <div className="dashboard-stepper">
            <StatusStageBar
              confirmPage={false}
              postTest={postTestProp}
              postSurvey={postCareProp}
              onPostTest={onPostTest}
              onPostSurvey={onPostCare}
            />
          </div>
          {/* Stepper End */}
          {/* Dashboard Card Start */}
          <div className="dashboard-container">
            <div className="dashboard-bottom-container">
              <table>
                <tr>
                  <td className="dashboard-bottom-img-td">
                    <div className="dashboard-bottom-img">
                      <img src={CareOption} alt="Care option" />
                    </div>
                  </td>
                  <td className="dashboard-hours-table">
                    <div className="dashboard-study-coordinator-title">Study coordinator hours</div>
                    <br/>
                    <span className="dashboard-study-coordinator-dates">October 19 - October 27</span>
                    <table>
                      <tr>
                        <td className="dashboard-times">
                          Monday-Friday
                        </td>
                        <td className="dashboard-times">
                          9 am - 5 pm ET
                        </td>
                      </tr>
                    </table>
                    <br />
                    <span className="dashboard-study-coordinator-dates">October 28 - December 13</span>
                    <table>
                      <tr>
                        <td className="dashboard-times">
                          Thursday
                        </td>
                        <td className="dashboard-times">
                          3 pm - 11 pm ET
                        </td>
                      </tr>
                      <tr>
                        <td className="dashboard-times">
                          Friday
                        </td>
                        <td className="dashboard-times">
                          3 pm - 11 pm ET
                        </td>
                      </tr>
                      <tr>
                        <td className="dashboard-times">
                          Saturday
                        </td>
                        <td className="dashboard-times">
                          7 am - 11 pm ET
                        </td>
                      </tr>
                      <tr>
                        <td className="dashboard-times">
                          Sunday
                        </td>
                        <td className="dashboard-times">
                          7 am - 11 pm ET
                        </td>
                      </tr>
                      <tr>
                        <td className="dashboard-times">
                          Monday
                        </td>
                        <td className="dashboard-times">
                          7 am - 12 pm ET
                        </td>
                      </tr>
                      <tr>
                        <td className="dashboard-times">
                          TUESDAY
                        </td>
                        <td className="dashboard-times">
                          OFF
                        </td>
                      </tr>
                      <tr>
                        <td className="dashboard-times">
                          Wednesday
                        </td>
                        <td className="dashboard-times">
                          8 am - 5 pm ET
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
              {/* <div className="dashboard-bottom-img">
                <img src={CareOption} alt="Care option" />
              </div>
              <div className="dashboard-bottom-info">
                {pageFooter}
                {pageFooterDetails}
              </div> */}
            </div>
            <div className="dashboard-row">
              {/* Card Start */}
              <div className="dashboard-card">
                <div className="dashboard-card-box">
                  <div className="dashboard-card-profile">
                    <img
                      className="dashboard-card-img"
                      src={CardProfile}
                      alt=""
                    />
                    <div className="dashboard-card-name">{name}</div>
                  </div>
                  <div className="dashboard-card-data">
                    <div className="card-first-item">
                      <div className="dashboard-date">Testing night</div>
                      <div className="dashboard-date-val">{testingNight}</div>
                      <div className="dashboard-date">
                        Study coordinator contact
                      </div>
                      <div className="dashboard-date-val">+1-833-324-2092</div>
                      {reportList.length > 0 ? (
                        reportList.map((report, index) => {
                          const date = report.substring(0, 10);
                          return (
                            <li className="list-report" key={`report${index}`}>
                              <br />
                              <span
                                id="share-report"
                                className="dashboard-change-password"
                                onClick={() => setShareDate(date)}
                              >
                                Share Sleep Report
                              </span>
                            </li>
                          );
                        })
                      ) : (
                        <div></div>
                      )}
                    </div>
                    <div className="card-second-item">
                      <div className="dashboard-icon-container">
                        <img
                          className="dashboard-card-email-img"
                          src={CardEmail}
                          alt=""
                        />
                        <pre className="dashboard-mail">{username}</pre>
                      </div>
                      <div className="dashboard-icon-container">
                        <img
                          className="dashboard-card-phone-img"
                          src={CardPhone}
                          alt=""
                        />
                        <pre className="dashboard-num">{phoneNumber}</pre>
                      </div>
                      <div className="dashboard-icon-container">
                        <img
                          className="dashboard-card-address-img"
                          src={CardAddress}
                          alt=""
                        />
                        <pre className="dashboard-des">{address}</pre>
                      </div>
                      <span
                        id="change-password"
                        className="dashboard-change-password"
                        onClick={onChangePassword}
                      >
                        Change Password
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* Card End */}
            </div>
          </div>
        </div>
        {/* Dashboard Stepper and Card End */}
        <Popup
          trigger={buttonPopup}
          passedData={
            "You are leaving the research study site and linking to virtual care options within your Anthem benefits. Other provider options may be available through your health plan.   Your follow up care is not part of the study, and is subject to the terms and conditions of your coverage."
          }
          setTrigger={setButtonPopup}
          onButtonClick={onCareOptionClicked}
        />
        <PostSurveyPopup
          trigger={surveyPopup}
          setTrigger={setSurveyPopup}
          survey={survey}
        />
        <EmailShare
          date={shareDate}
          share={onShareReport}
          setDate={setShareDate}
        />
        <div className="dashboard-footer">
          <PageFooter />
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

export default Dashboard;
